@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#textHome {
  font-family: "Bebas Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.animate-slider {
  animation: slide 12s linear infinite; /* 6-second infinite loop */
}

.top_left,
.bottom_left,
.bottom_right {
  position: relative;
  width: 100%;
  overflow: hidden; /* Prevent overflow */
  height: 20px; /* Fixed height for marquee */
}

.track {
  display: flex;
  width: calc(200%); /* Double the width to allow seamless looping */
  animation: marquee 15s linear infinite; /* Adjust speed here */
}

.content {
  display: inline-block;
  display: flex;
  font-weight: 900;
  justify-content: center;
  align-items: center;
  align-content: center;
  white-space: nowrap; /* Prevent line breaks */
  color: black; /* Text color */
  font-size: 12px; /* Font size */
  letter-spacing: 3px; /* Spacing between letters */
  padding: 2px 0; /* Padding for content */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  display: flex;
  width: calc(300%); /* Adjust based on the number of images */
  animation: scroll 30s linear infinite;
}

.icon-black {
  color: black !important; /* Force the icon color to be black */
}

/* background  */
#stricker {
  font-family: "Striker", sans-serif;
  font-size: 24px;
  color: #334774; /* Updated to the new color */
}

#award {
  font-family: "Striker", sans-serif;
}

.top_left {
  position: relative;
  width: 100%;
  overflow: hidden; /* Prevent overflow */
  height: 20px; /* Fixed height for marquee */
}

.track {
  display: flex;
  width: calc(200%); /* Double the width to allow seamless looping */
  animation: marquee 15s linear infinite; /* Adjust speed here */
}

.content {
  display: inline-block;
  /* background: #162a58; */
  background: white;
  white-space: nowrap; /* Prevent line breaks */
  color: #162a58;
  font-size: 12px; /* Font size */
  letter-spacing: 3px; /* Spacing between letters */
  padding: 5px 0; /* Padding for content */
}

@keyframes marquee {
  from {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.top_left,
.bottom_left,
.bottom_right {
  position: relative;
  width: 100%;
  overflow: hidden; /* Prevent overflow */
  height: 20px; /* Fixed height for marquee */
}
.marquee-container {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  background-color: rgb(251, 243, 243);
}

.marquee {
  display: flex;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}

.marquee-content {
  background: white;
  display: inline-block;
  margin: 3px;
  color: #334774;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
}

@keyframes marquee {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.slide-in {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-in-visible {
  opacity: 1;
  transform: translateY(0);
}

/* social sldier   */
.slide-in {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.slide-in-visible {
  opacity: 1;
  transform: translateY(0);
}
